import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { ReactComponent as ContainersLogoSVG } from "../assets/images/container-icon.svg";
import { ReactComponent as CreateIconSVG } from "../assets/images/create-icon.svg";
import { ReactComponent as LinkIconSVG } from '../assets/images/link-icon.svg';
import { ReactComponent as MailCircleSVG } from '../assets/images/mail-circle-icon.svg';
import { ReactComponent as SearchIcon } from "../assets/images/search-icon.svg";
import { ReactComponent as OutlinedStarSVG } from "../assets/images/star-outline.svg";
import { ReactComponent as FilledStarSVG } from "../assets/images/star-filled.svg";
import { ReactComponent as StatusErrorSVG } from '../assets/images/status-error.svg';
import LoadingMessage from "../components/LoadingMessage";
import ErrorMessage from "../components/ErrorMessage";
import GhostMessage from "../components/GhostMessage";
import Guides from "../components/Guides";
import { App, AuthenticationError, AuthorizationError, NetworkError, NotFoundError, Subscription } from "@back4app2/sdk";
import { useEffect, useReducer } from "react";
import back4app2 from "../back4app2";
import { BACK4APP_DOT_COM_SITE_URL } from "../settings";
import { AmplitudeEvent, trackEvent } from "../utils/amplitude";
import Chip, { ChipType } from "../components/Chip";
import { AppStatus } from "@back4app2/sdk";
import { toast } from "react-hot-toast";
import TextInput from "../components/FormInputField/TextInput/TextInput";
import MyAppStatus from "../components/MyAppStatus";
import { debounceFunction } from "../utils";

interface MyAppsState {
  isLoading: boolean;
  loadingErrorMessage?: string;
  apps?: App[];
  filteredApps?: App[];
  searchText: string;
}

const INITIAL_STATE: MyAppsState = {
  isLoading: true,
  searchText: ''
};

enum MyAppsActionType {
  RESET,
  FINISH_LOADING,
  SET_FILTERED_APPS
}

const reset = () => ({
  type: MyAppsActionType.RESET
} as const);

const finishLoading = (errorMessage?: string, apps?: App[]) => ({
  type: MyAppsActionType.FINISH_LOADING,
  payload: {
    errorMessage,
    apps
  }
} as const);

const setFilteredApps = (searchText: string) => ({
  type: MyAppsActionType.SET_FILTERED_APPS,
  payload: {
    searchText,
  }
} as const);

type MyAppsAction = ReturnType<typeof reset> | ReturnType<typeof finishLoading> | ReturnType<typeof setFilteredApps>;

const reducer = (state: MyAppsState = INITIAL_STATE, action: MyAppsAction): MyAppsState => {
  const getFilteredApps = (apps?: App[], searchText?: string) => {
    let filteredApps = (apps && [...apps]) || [];
    if (searchText) {
      const regex = new RegExp(`${searchText}`, 'ig');
      filteredApps = filteredApps.filter(app => app.name.match(regex)) || [];
    }
    filteredApps = filteredApps.sort((a, b) => b.isFavourite === a.isFavourite ? a.name.localeCompare(b.name) : +b.isFavourite - +a.isFavourite);
    return filteredApps;
  };

  switch (action.type) {
    case MyAppsActionType.RESET:
      return INITIAL_STATE;
    
    case MyAppsActionType.FINISH_LOADING:
      return {
        ...state,
        isLoading: false,
        loadingErrorMessage: action.payload.errorMessage,
        apps: action.payload.apps,
        filteredApps: getFilteredApps(action.payload.apps, state.searchText)
      };

    case MyAppsActionType.SET_FILTERED_APPS: {
      
      return {
        ...state,
        filteredApps: getFilteredApps(state.apps, action.payload.searchText),
        searchText: action.payload.searchText
      };
    }
  }
};

const MyApps = () => {
  const { freePlanUsageHours } = useOutletContext<{ freePlanUsageHours: number }>();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  let { isLoading, loadingErrorMessage, apps, filteredApps, searchText } = state;
  const navigate = useNavigate();

  useEffect(
    () => {
      let subscription: Subscription;

      subscription = back4app2.subscribeToApps(
        (error, snapshot) => {
          if (error) {
            if (error instanceof NetworkError) {
              console.error('network error', error);
              dispatch(finishLoading('Network error when loading apps. Check your internet connection and try again.'));
            } else if (error instanceof AuthenticationError || error instanceof AuthorizationError) {
              window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
            } else {
              console.error('unexpected error loading apps', error);
              dispatch(finishLoading('Unexpected error when loading apps. Please try again.'));
            }
          } else {
            dispatch(finishLoading(undefined, snapshot));
          }
        }
      );

      return () => {
        if (subscription) {
          subscription.unsubscribe();
          dispatch(reset());
        }
      };
    },
    []
  );

  useEffect(() => {
    trackEvent(AmplitudeEvent.AT_CONTAINERS_DASHBOARD_PAGE);
  }, []);

  const handleToggleAppFavourite = async (appId: string) => {
    try {
      const selectedApp = apps?.find(app => app.id === appId);
      if (!selectedApp) {
        return;
      }
      await back4app2.updateAppFavourite(selectedApp.id, !selectedApp.isFavourite);
    } catch (err) {
      let errMsg = 'Something went wrong!';
      if (err instanceof NetworkError) {
        console.error('network error', err);
        errMsg = 'Network error when updating app favourites. Check your internet connection and try again.'
      } else if (err instanceof AuthenticationError || err instanceof AuthorizationError) {
        window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
      } else if (err instanceof NotFoundError) {
        errMsg = 'App not found!';
      } else {
        console.log('error while updating favourites ', err);
      }
      toast(errMsg, {
        className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-xs text-sm",
        duration: 3 * 1000,
        icon: <StatusErrorSVG width="24px" height="24px" className="animate-bounce-in" />
      });
    }
  }

  const favouriteAppsCount = filteredApps?.filter(app => app.isFavourite).length || 0;

  return (<div className="overflow-y-scroll">
    <div className="mx-auto w-full max-w-[55rem] px-5 py-[3.75rem] flex flex-col gap-[2.875rem] items-center">
      <div className="w-full flex flex-col gap-6">
        <div className="h-[3.1875rem] lg:h-[5.3125rem]">
          <div className="lg:py-2.5">
            <div className="relative py-2.5 flex gap-2.5 items-center">
              <h1 className="font-sora font-bold lg:font-semibold text-[1.375rem] lg:text-[2.1875rem] leading-[140%] lg:leading-[130%]">
                Manage Your Web Applications
              </h1>
              <Chip text="beta" type={ChipType.SUCCESS} />
              <div
                className="absolute -left-0.5 top-[3.1875rem] lg:top-[4.4375rem] w-6 h-[0.1875rem] rounded"
                style={{
                  background: 'linear-gradient(90deg, #208AEC -8.33%, #27AE60 100%)',
                  boxShadow: '0px 0px 7px rgba(193, 226, 255, 0.16)'
                }}>
              </div>
            </div>
          </div>
        </div>
        <div className="leading-[140%] opacity-70 lg:opacity-100">
          Explore your existing applications or kickstart a new project with ease.
        </div>
      </div>
      <div className="w-full flex flex-col gap-6">
        <div className="flex">
          <button onClick={() => {
            if (state.apps?.some(app => app.status === AppStatus.PENDING_VERIFICATION)) {
              toast('Please complete email verification!', {
                className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-xs text-sm",
                duration: 3 * 1000,
                icon: <MailCircleSVG className="text-error-red" width="22px" height="22px" />
              });
              return;
            }
            navigate('/new-container');
          }} className="box-border border border-solid border-regal-blue rounded bg-dark hover:bg-regal-blue pr-4 flex items-center">
            <div className="p-2.5">
              <CreateIconSVG />
            </div>
            <div className="leading-[140%]">
              Deploy a Web app
            </div>
          </button>
        </div>
        <div className="px-6 py-4 rounded-lg bg-light-grey/5 flex flex-col md:flex-row md:justify-between md:items-center">
          <div className="flex gap-4 items-center">
            <div className="font-sora text-lg font-normal leading-140 flex-none">
              Free web applications 
            </div>
            <span className="bg-dark-grey rounded-full flex justify-center items-center min-w-[2rem] min-h-[2rem]">
              <span className="w-full text-center">
                {apps ? apps.filter(app => app.mainService?.mainServiceEnvironment?.plan?.name?.includes('Free')).length : ''}
              </span>
            </span>
          </div>
          <div className="max-w-xs mt-2 md:mt-0">
            <AppPlanUsage hours={freePlanUsageHours || 0} />
          </div>
        </div>
        <div className="box-border min-h-[20rem]">
          {isLoading ? (
            <div className="border border-regal-blue rounded h-full flex items-center min-h-[20rem]"><LoadingMessage message="Loading apps" /></div>
          ) : (loadingErrorMessage ? (
            <ErrorMessage message={loadingErrorMessage} />
          ) : (!apps || apps.length <= 0 ? (
            <div className="border border-regal-blue rounded h-full flex items-center min-h-[20rem]"><GhostMessage /></div>
          ) : (
            <>
              <div className="flex flex-col md:flex-row justify-between md:items-center mb-6">
                <span className="text-sm font-bold mb-2 md:mb-0">{`${apps.length} web application${(apps.length === 1 || apps.length === 0) ? '' : 's'}`}</span>
                <div className="flex flex-col md:flex-row gap-8">
                  <TextInput value={searchText} onChange={(e) => dispatch(setFilteredApps(e.target.value))} placeholderText="Search" className="px-3 py-1.5 flex-grow flex-shrink-0 min-w-[20rem]" endAdornment={<SearchIcon className="text-light-grey" width={"18px"} height={"18px"} />} />
                </div>
              </div>
              {!filteredApps || filteredApps.length <=0 ? (
                <>Try different filter</>
              ) : (
                filteredApps.map((app, idx) => (
                  <div key={app.id}>
                    <div className="flex flex-col border border-regal-blue rounded-lg drop-shadow-[0_6px_16px_rgba(0,0,0,0.1)] px-4 py-3 mb-4">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-[0.625rem] flex-none max-w-[50%] lg:max-w-[60%]">
                          <ContainersLogoSVG color="text-cta-green" width="34px" height="34px" />
                          <div className="lg:max-w-[60%] font-bold leading-[140%] truncate">
                            <Link to={`/apps/${app.id}`}>
                              {app.name}
                            </Link>
                          </div>
                          {app.mainService?.mainServiceEnvironment?.plan ? <AppPlanChip planName={app.mainService?.mainServiceEnvironment?.plan.name || ''} /> : null}
                        </div>
                        <button onClick={() => {
                          const debouncedFunction = debounceFunction(handleToggleAppFavourite, 500);
                          debouncedFunction(app.id);
                        }} >{app.isFavourite ? <FilledStarSVG /> : <OutlinedStarSVG />}</button>
                      </div>
                      <div className="flex justify-between items-center md:mx-10">
                        {app.mainService && app.mainService.mainServiceEnvironment && app.mainService.mainServiceEnvironment.mainCustomDomain && app.mainService.mainServiceEnvironment.mainCustomDomain.name && (
                          <a target="_blank" href={`${window.location.protocol}//${app.mainService.mainServiceEnvironment.mainCustomDomain.name}`} rel="noreferrer" className="lg:max-w-[40%] inline-flex items-center space-x-2">
                            <span className="text-cta-green text-xs leading-[140%] truncate hover:underline hover:underline-offset-2">{app.mainService.mainServiceEnvironment.mainCustomDomain.name}</span>
                            <LinkIconSVG className="flex-none" />
                          </a>
                        )}
                        <MyAppStatus app={app} />
                      </div>
                    </div>
                    {(favouriteAppsCount - 1 === idx) && favouriteAppsCount !== filteredApps?.length ? <hr className="border-dashed border-regal-blue mb-4"/> : null}
                  </div>
                ))
              )}
            </>
          )))}
        </div>
      </div>
      <div className="w-full p-4">
        <Guides />
      </div>
    </div>
  </div>);
};

const AppPlanUsage = (props: { hours: number }) => {
  const { hours } = props;
  const usagePercentage = (hours / 600) * 100;

  let progressBarColour = 'bg-light-blue', hourTextColor = 'text-light-blue';
  if (usagePercentage >= 80 && usagePercentage <= 99) {
    progressBarColour = 'bg-alert-yellow';
    hourTextColor = 'text-alert-yellow';
  } else if (usagePercentage >= 100) {
    progressBarColour = 'bg-error-red'
    hourTextColor = 'text-error-red'
  }

  return <>
    <div className="text-sm mb-[0.375rem] w-full flex gap-6">
      <span>Build + Running hours</span> 
      <span className={`${hourTextColor}`}>{hours} <span className="text-light-grey">/ 600 hours</span></span>
    </div>
    <div className="w-full bg-dark-grey rounded-full h-1.5">
      <div className={`${progressBarColour} h-1.5 rounded-full max-w-full`} style={{ width: `${usagePercentage}%`}}></div>
    </div>
  </>
}

const AppPlanChip = (props: {planName: string}) => {
  const { planName } = props;
  let chipColor = `bg-old-dark-blue text-old-blue`;
  if (planName.includes('Shared')) {
    chipColor = `bg-[rgba(24,103,57,0.6)] text-cta-green`;
  } else if (planName.includes('Dedicated')) {
    chipColor = `bg-old-dark-blue text-old-blue`; // TODO: confirm color
  }

  return <Chip type={ChipType.DEFAULT} text={planName} className={`border-none ${chipColor} capitalize font-normal text-xs`} />
}

export default MyApps;
