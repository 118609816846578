import { App, BlockedResourceError, NotFoundError, UserVerificationPendingError, AppStatusError, AppStatus, PaymentPendingError } from "@back4app2/sdk";
import { useState, useRef, useCallback, useEffect } from "react";
import back4app2 from "../back4app2";
import { ReactComponent as DownArrowIcon } from "../assets/images/down-arrow.svg";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { ReactComponent as StatusErrorSVG } from '../assets/images/status-error.svg';
import { ReactComponent as StatusSuccessSVG } from '../assets/images/status-success.svg';
import { ReactComponent as StatusSpinnerSVG } from '../assets/images/status-spinner.svg';
import { AmplitudeEvent, trackEvent } from "../utils/amplitude";

const MyAppActions = ({ app }: { app: App }) => {
  const mainServiceEnvironment = app.mainService && app.mainService.mainServiceEnvironment;
  const mainServiceEnvironmentExists = !!mainServiceEnvironment;
  const navigate = useNavigate();

  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutsideEvent = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    },
    [],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideEvent);
    document.addEventListener('touchstart', handleClickOutsideEvent);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideEvent);
      document.removeEventListener('touchstart', handleClickOutsideEvent);
    }
  }, [handleClickOutsideEvent]);

  const deployLatestCommit = async () => {
    setIsOpen(false);
    trackEvent(AmplitudeEvent.DEPLOY_LATEST_COMMIT);
    const toastId = toast.loading('Deploying latest commit...', {
      className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-xs text-sm",
      icon: <StatusSpinnerSVG width="24px" height="24px" color="text-old-blue" className="animate-spin" />
    });
    try {
      await back4app2.triggerManualDeployment(mainServiceEnvironment?.id as string);
      toast.success('New deployment created!', {
        className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-xs text-sm",
        id: toastId,
        icon: <StatusSuccessSVG width="24px" height="24px" className="animate-bounce-in top-" />
      });
      navigate(`/apps/${app.id}`);
    } catch (err) {
      let errMsg = 'Something went wrong!';
      if (err instanceof AppStatusError || err instanceof UserVerificationPendingError || err instanceof BlockedResourceError || err instanceof PaymentPendingError || err instanceof NotFoundError) {
        errMsg = err.message;
      }
      toast.error(errMsg, {
        className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] min-w-xs text-sm",
        id: toastId,
        icon: <StatusErrorSVG width="24px" height="24px" className="animate-bounce-in" />,
        duration: 6000
      });
    }
  }

  if (!mainServiceEnvironmentExists || [AppStatus.INITIALIZING, AppStatus.PENDING_VERIFICATION, AppStatus.FAILED].includes(app.status) || mainServiceEnvironment.isPendingPayment || (!mainServiceEnvironment.isFreePlanElegible && mainServiceEnvironment.plan.name.includes('Free'))) {
    return null;
  }

  return <div className="max-w-lg mx-auto mt-4 relative" ref={ref}>
    <button 
      className={`w-full bg-[#2D384B] px-[0.625rem] py-[0.4375em] outline-none border border-white rounded-[0.3125rem] hover:cursor-pointer flex items-center justify-between font-medium text-sm leading-140 ${isOpen ? 'border-b-0 rounded-br-none rounded-bl-none' : ''}`} 
      type="button"
      onClick={() => setIsOpen(prev => !prev)}
    >
      Action
      <DownArrowIcon width="14px" height="10px" className={`ml-2 transition-all duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`} />
    </button>
    <div className={`${isOpen ? 'opacity-100' : 'opacity-0 hidden'} absolute top-full left-0 overflow-hidden w-full transition-all duration-300 bg-[#2D384B] text-sm z-[999] list-none divide-y divide-gray-100 border border-white border-t-0 rounded-bl-[5px] rounded-br-[5px]`}>
      <ul className="py-1">
        <li className="hover:cursor-pointer hover:bg-[#34506F] transition-all duration-200 px-[0.625rem] py-[0.4375em]" onClick={deployLatestCommit}>Deploy the latest commit</li>
        {/* <li className="hover:cursor-pointer hover:bg-[#34506F] transition-all duration-200 px-[0.625rem] py-[0.4375em]">Deploy a specific commit</li>
        <li className="hover:cursor-pointer hover:bg-[#34506F] transition-all duration-200 px-[0.625rem] py-[0.4375em]">SuspendApp/Resume App</li> */}
      </ul>
  </div>
</div>;
};

export default MyAppActions;
