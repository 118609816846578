import { AuthenticationError, AuthorizationError, CustomDomain, CustomDomainStatus, CustomDomainStatusError, DuplicatedError, InvalidInputError, NetworkError, NotFoundError, Subscription } from "@back4app2/sdk";
import { useEffect, useReducer, useRef, useState } from "react";
import back4app2 from "../back4app2";
import { BACK4APP_DOT_COM_SITE_URL } from "../settings";
import LoadingSpinner from "./LoadingSpinner";
import GhostMessage from "./GhostMessage";
import SelectInput from "./FormInputField/SelectInput/SelectInput";
import TextInput from "./FormInputField/TextInput/TextInput";
import ErrorMessage from "./ErrorMessage";
import MyCustomDomainStatus from "./CustomDomainStatus";
import { ReactComponent as CloseIcon } from '../assets/images/close-icon.svg';
import CustomDomainActions from "./CustomDomainActions";
import { AmplitudeEvent, trackEvent } from "../utils/amplitude";

import { ReactComponent as StatusErrorSVG } from '../assets/images/status-error.svg';
import { ReactComponent as StatusSuccessSVG } from '../assets/images/status-success.svg';
import { ReactComponent as StatusSpinnerSVG } from '../assets/images/status-spinner.svg';
import { ReactComponent as CopySVG } from '../assets/images/copy-icon.svg';

import toast from "react-hot-toast";
import ReactTooltip from "react-tooltip";

interface CustomDomainSettingsState {
  customDomainsLoadingErrorMessage?: string;
  customDomains?: CustomDomain[];
  isLoadingCustomDomains: boolean;
  showAddCustomDomainForm: boolean;
  addCustomDomainFormInput: {
    customDomainInput: { 
      value: string,
      isValid: boolean,
      errorMsg?: string,
    },
    redirectTo?: {
      id: string,
      name: string
    }
  },
  isCreatingCustomDomain: boolean;
  createCustomDomainError?: string;
  showDNSConfig?: CustomDomain;
  firstNewCustomDomainId?: string; // for scrolling to newly added customdomain
}

const INITIAL_STATE: CustomDomainSettingsState = {
  isLoadingCustomDomains: true,
  showAddCustomDomainForm: false,
  addCustomDomainFormInput: {
    customDomainInput: {
      value: '',
      isValid: true,
      errorMsg: ''
    }
  },
  isCreatingCustomDomain: false
}

enum CustomDomainSettingsActionType {
  FINISH_LOADING_CUSTOM_DOMAINS,
  RESET,
  SET_CUSTOM_DOMAIN_FORM,
  SET_CUSTOM_DOMAIN_FORM_INPUT_TEXT,
  SET_CUSTOM_DOMAIN_REDIRECT_TO,
  START_CREATING_CUSTOM_DOMAIN,
  FINISH_CREATING_CUSTOM_DOMAIN,
  SET_SHOW_DNS_CONFIG,
  REMOVE_NEWLY_CREATED_CD_ID
}

const finishLoadingCustomDomains = (errorMessage?: string, customDomains?: CustomDomain[], mainCustomDomainId?: string) => ({
  type: CustomDomainSettingsActionType.FINISH_LOADING_CUSTOM_DOMAINS,
  payload: {
    errorMessage,
    customDomains,
    mainCustomDomainId
  }
} as const);

const reset = () => ({
  type: CustomDomainSettingsActionType.RESET
} as const);

const setCreateCustomDomainForm = (show: boolean) => ({
  type: CustomDomainSettingsActionType.SET_CUSTOM_DOMAIN_FORM,
  payload: show
} as const);

const setCustomDomainFormInputText = (newValue: string, errorMsg?: string) => ({
  type: CustomDomainSettingsActionType.SET_CUSTOM_DOMAIN_FORM_INPUT_TEXT,
  payload: {
    newValue,
    errorMsg
  }
} as const);

const setCreateCustomDomainRedirectTo = (customDomainId: string) => ({
  type: CustomDomainSettingsActionType.SET_CUSTOM_DOMAIN_REDIRECT_TO,
  payload: customDomainId
} as const);

const startCreatingCustomDomain = () => ({
  type: CustomDomainSettingsActionType.START_CREATING_CUSTOM_DOMAIN
} as const);

const finishCreatingCustomDomain = (errorMessage?: string, newCustomDomain?: CustomDomain) => ({
  type: CustomDomainSettingsActionType.FINISH_CREATING_CUSTOM_DOMAIN,
  payload: {
    errorMessage,
    newCustomDomain
  }
} as const);

const setShowDNSConfig = (customDomainId?: string) => ({
  type: CustomDomainSettingsActionType.SET_SHOW_DNS_CONFIG,
  payload: customDomainId
} as const);

const removeNewlyCreatedCustomDomainId = () => ({
  type: CustomDomainSettingsActionType.REMOVE_NEWLY_CREATED_CD_ID
} as const);

type CustomDomainSettingsAction = ReturnType<typeof reset> | ReturnType<typeof finishLoadingCustomDomains> | ReturnType<typeof setCreateCustomDomainForm> | ReturnType<typeof setCustomDomainFormInputText> | ReturnType<typeof setCreateCustomDomainRedirectTo> | ReturnType<typeof startCreatingCustomDomain> | ReturnType<typeof finishCreatingCustomDomain> | ReturnType<typeof setShowDNSConfig> | ReturnType<typeof removeNewlyCreatedCustomDomainId>;

const reducer = (state: CustomDomainSettingsState = INITIAL_STATE, action: CustomDomainSettingsAction): CustomDomainSettingsState => {
  switch (action.type) {
    case CustomDomainSettingsActionType.RESET:
      return INITIAL_STATE;
    
    case CustomDomainSettingsActionType.FINISH_LOADING_CUSTOM_DOMAINS:
      let sorted = action.payload.customDomains;
      if (action.payload.customDomains) {
        sorted = action.payload.customDomains.sort((a, b) => a.name.localeCompare(b.name))
        let mainCustomDomainIdx = sorted.findIndex(c => c.id === action.payload.mainCustomDomainId);
        if (mainCustomDomainIdx > 0) {
          let mainCustomDomain = sorted[mainCustomDomainIdx];
          sorted = action.payload.customDomains.filter(c => c.id !== action.payload.mainCustomDomainId);
          sorted.unshift(mainCustomDomain);
        }
      }
      return {
        ...state,
        isLoadingCustomDomains: false,
        customDomainsLoadingErrorMessage: action.payload.errorMessage,
        customDomains: sorted
      }

    case CustomDomainSettingsActionType.SET_CUSTOM_DOMAIN_FORM:
      return {
        ...state,
        showAddCustomDomainForm: action.payload,
        addCustomDomainFormInput: {
          ...state.addCustomDomainFormInput,
          customDomainInput: {
            value: '',
            isValid: true,
            errorMsg: ''
          },
          redirectTo: undefined
        }
      }

    case CustomDomainSettingsActionType.SET_CUSTOM_DOMAIN_FORM_INPUT_TEXT: 
    return {
      ...state,
      addCustomDomainFormInput: {
        ...state.addCustomDomainFormInput,
        customDomainInput: {
          ...state.addCustomDomainFormInput.customDomainInput,
          value: action.payload.newValue.trim(),
          errorMsg: action.payload.errorMsg,
          isValid: !action.payload.errorMsg
        }
      }
    }

    case CustomDomainSettingsActionType.SET_CUSTOM_DOMAIN_REDIRECT_TO: {
      const selectedCustomDomain = state.customDomains?.find(customDomain => customDomain.id === action.payload);
      return {
        ...state,
        addCustomDomainFormInput: {
          ...state.addCustomDomainFormInput,
          redirectTo: selectedCustomDomain
        }
      }
    }

    case CustomDomainSettingsActionType.START_CREATING_CUSTOM_DOMAIN: 
      return {
        ...state,
        isCreatingCustomDomain: true
      }
    
    case CustomDomainSettingsActionType.FINISH_CREATING_CUSTOM_DOMAIN: 
      return {
        ...state,
        addCustomDomainFormInput: {
          customDomainInput: {
            value: '',
            isValid: true,
            errorMsg: ''
          },
          redirectTo: undefined
        },
        isCreatingCustomDomain: false,
        createCustomDomainError: action.payload.errorMessage,
        showAddCustomDomainForm: false,
        firstNewCustomDomainId: action.payload.newCustomDomain?.id || ''
      }
    
    case CustomDomainSettingsActionType.SET_SHOW_DNS_CONFIG: {
      const selectedCustomDomain = state.customDomains?.find(customDomain => customDomain.id === action.payload) || undefined;
      return {
        ...state,
        showDNSConfig: selectedCustomDomain
      }
    }

    case CustomDomainSettingsActionType.REMOVE_NEWLY_CREATED_CD_ID: 
      return {
        ...state,
        firstNewCustomDomainId: undefined
      }
  }
};

const CustomDomainSettings = (props: { serviceEnvironmentId: string, mainCustomDomainId: string }) => {
  const { serviceEnvironmentId, mainCustomDomainId } = props;
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const addFormRef = useRef<HTMLDivElement>(null);
  const newCustomDomainRef = useRef<HTMLDivElement>(null);
  const { customDomains, customDomainsLoadingErrorMessage, isLoadingCustomDomains, showAddCustomDomainForm, addCustomDomainFormInput, showDNSConfig, isCreatingCustomDomain, firstNewCustomDomainId } = state;
  const { customDomainInput, redirectTo } = addCustomDomainFormInput;

  useEffect(() => {
    let subscription: Subscription;
    if (serviceEnvironmentId) {
      subscription = back4app2.subscribeToCustomDomains(serviceEnvironmentId, (error, snaphost) => {
        if (error) {
          if (error instanceof NetworkError) {
            console.error('network error', error);
            dispatch(finishLoadingCustomDomains('Network error when loading custom domains. Check your internet connection and try again.'));
          } else if (error instanceof AuthenticationError || error instanceof AuthorizationError) {
            window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
          } else {
            console.error('unexpected error loading last deployment logs', error);
            dispatch(finishLoadingCustomDomains('Unexpected error when loading custom domains. Please try again.'));
          }
        } else {
          dispatch(finishLoadingCustomDomains(undefined, snaphost, mainCustomDomainId));
        }
      });
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
        dispatch(reset());
      }
    }
  }, [serviceEnvironmentId, mainCustomDomainId]);

  useEffect(() => {
    if (showAddCustomDomainForm && addFormRef.current) {
      addFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showAddCustomDomainForm]);

  useEffect(() => {
    if (firstNewCustomDomainId && newCustomDomainRef.current) {
      newCustomDomainRef.current.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        dispatch(removeNewlyCreatedCustomDomainId());
      }, 1000);
    }
  }, [firstNewCustomDomainId]);

  const createCustomDomain = async () => {
    const regex = new RegExp(/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i);
    if (!regex.test(customDomainInput.value)) {
      dispatch(setCustomDomainFormInputText(customDomainInput.value, 'Please enter valid domain'));
      return;
    }
    dispatch(startCreatingCustomDomain());
    trackEvent(AmplitudeEvent.CLICK_ON_CREATE_DOMAIN_BTN);
    const toastId = toast.loading(`Creating custom domain "${customDomainInput.value}"`, {
      className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-md text-sm",
      icon: <StatusSpinnerSVG width="24px" height="24px" color="text-old-blue" className="animate-spin flex-none" />
    });
    try {
      const response = await back4app2.createCustomDomain(serviceEnvironmentId, customDomainInput.value, redirectTo?.id);
      toast.success(`"${response.name}" custom domain created successfully!`, {
        className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-md text-sm",
        id: toastId,
        icon: <StatusSuccessSVG width="24px" height="24px" className="animate-bounce-in" />
      });
      dispatch(finishCreatingCustomDomain(undefined, response));
    } catch (err) {
      let errMsg = 'Something went wrong!';
      if (err instanceof NetworkError) {
        errMsg = 'Network error when creating custom domain. Check your internet connection and try again.';
      } else if (err instanceof AuthenticationError || err instanceof AuthorizationError) {
        window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
      } else if (err instanceof DuplicatedError) {
        errMsg = 'Domains must be unqiue!';
      } else if (err instanceof InvalidInputError) {
        errMsg = err.message;
      } else {
        console.log('creating custom domain error', err);
        errMsg = 'Something went wrong, please try again.';
      }
      toast.error(errMsg, {
        className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-xs text-sm",
        id: toastId,
        icon: <StatusErrorSVG width="24px" height="24px" />,
        duration: 6000
      });
      dispatch(finishCreatingCustomDomain(errMsg, undefined));
    }
  }

  const deleteCustomDomain = async (customDomain: CustomDomain) => {
    try {
      await back4app2.deleteCustomDomain(customDomain.id);
    } catch (err) {
      let errMsg = '';
      if (err instanceof NetworkError) {
        errMsg = 'Network error when creating custom domain. Check your internet connection and try again.';
      } else if (err instanceof AuthenticationError || err instanceof AuthorizationError) {
        window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
      } else if (err instanceof NotFoundError || err instanceof CustomDomainStatusError) {
        errMsg = err.message;
      } else {
        console.log('err while deleting custom domain', err);
        errMsg =  'Something went wrong, please try again.';
      }
      toast.error(errMsg, {
        className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-xs text-sm",
        icon: <StatusErrorSVG width="24px" height="24px" className="animate-bounce-in" />,
        duration: 6000
      });
    }
  }

  const updateCustomDomain = async (customDomain: CustomDomain, redirectTo: string) => {
    const toastId = toast.loading(`Updating custom domain "${customDomain.name}"`, {
      className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-md text-sm",
      icon: <StatusSpinnerSVG width="24px" height="24px" color="text-old-blue" className="animate-spin flex-none" />
    });
    try {
      const response = await back4app2.updateCustomDomain(customDomain.id, redirectTo ? redirectTo : undefined);
      toast.success(`"${response.name}" custom domain updated successfully!`, {
        className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-md text-sm",
        id: toastId,
        icon: <StatusSuccessSVG width="24px" height="24px" className="animate-bounce-in top-" />
      });
    } catch (err) {
      let errMsg = '';
      if (err instanceof NetworkError) {
        errMsg = 'Network error when updating custom domain. Check your internet connection and try again.';
      } else if (err instanceof AuthenticationError || err instanceof AuthorizationError) {
        window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
      } else if (err instanceof NotFoundError || err instanceof CustomDomainStatusError) {
        errMsg = err.message;
      } else {
        console.log('err while updating custom domain', err);
        errMsg =  'Something went wrong, please try again.';
      }
      toast.error(errMsg, {
        className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-xs text-sm",
        id: toastId,
        icon: <StatusErrorSVG width="24px" height="24px" className="animate-bounce-in" />,
        duration: 6000
      });
    }
  }

  const verifyCustomDomainDNS = async (customDomainId: string) => {
    try {
      await back4app2.verifyCustomDomainDNS(customDomainId);
    } catch (err) {
      let errMsg = '';
      if (err instanceof NetworkError) {
        errMsg = 'Network error when updating custom domain. Check your internet connection and try again.';
      } else if (err instanceof AuthenticationError || err instanceof AuthorizationError) {
        window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
      } else if (err instanceof NotFoundError || err instanceof CustomDomainStatusError) {
        errMsg = err.message;
      } else {
        console.log('err while verifying custom domain DNS again', err);
        errMsg =  'Something went wrong, please try again.';
      }
      toast.error(errMsg, {
        className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-xs text-sm",
        icon: <StatusErrorSVG width="24px" height="24px" className="animate-bounce-in" />,
        duration: 6000
      });
    }
  }

  const redirectToOptionList = customDomains?.filter(customDomain => ![CustomDomainStatus.DELETING, CustomDomainStatus.FAILED].includes(customDomain.status))?.map(customDomain => ({
    displayText: customDomain.name,
    value: customDomain.id
  })) || [];

  redirectToOptionList.unshift({ displayText: 'No Redirect', value: ''});

  return (
    <>
      {isLoadingCustomDomains ? <div className="w-full flex justify-center"><LoadingSpinner /></div> : null}
      {customDomainsLoadingErrorMessage ? <div className="w-full flex justify-center"><ErrorMessage message={customDomainsLoadingErrorMessage} /></div> : null}
      {customDomains && !customDomains.length ? <div className="w-full flex justify-center"><GhostMessage /></div> : null}
      {customDomains && customDomains.map((domain) => (
        <div key={domain.id} ref={firstNewCustomDomainId === domain.id ? newCustomDomainRef : undefined}>
          <div className='w-full flex justify-between items-start mb-6'>
            <div>
              <div className="flex gap-[0.625rem] flex-wrap items-baseline mb-2">
                <a
                  href={`${window.location.protocol}//${domain.name}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='inline-block text-cta-green hover:underline hover:underline-offset-2 text-lg truncate max-w-xs'
                >
                  {domain.name}
                </a>
                <MyCustomDomainStatus customDomain={domain} onVerifyDNS={verifyCustomDomainDNS} />
              </div>
              <div className='text-sm'>{domain.id === mainCustomDomainId ? 'Your Back4app\'s subdomain' : 'Your Web application\'s custom domain'}</div>
            </div>
            <CustomDomainActions 
              onDeleteCustomDomain={deleteCustomDomain} 
              onUpdateCustomDomain={updateCustomDomain} 
              customDomain={domain} 
              redirectOptionList={redirectToOptionList.filter(option => option.value !== domain.id)} 
              onShowDNSConfig={(customDomainId: string) => dispatch(setShowDNSConfig(customDomainId))}
              mainCustomDomainId={mainCustomDomainId}
            />
          </div>
          {(domain.status === CustomDomainStatus.AWAITING_DNS && domain.id !== mainCustomDomainId) || showDNSConfig?.id === domain.id ? <DNSInformationBox customDomain={domain} forceShow={showDNSConfig?.id === domain.id} forceClose={() => dispatch(setShowDNSConfig(undefined))} /> : null}
        </div>
      ))}
      <div className='max-w-[50rem] mt-8'>
        {showAddCustomDomainForm ? (
          <div ref={addFormRef}>
            <div className='font-bold mb-2'>Add Domain</div>
            <div className='text-sm'>
              Once you&apos;ve entered your domain name, you&apos;ll need to prove ownership, typically by adding a TXT or CNAME record to its DNS settings. For detailed steps, 
              <a
                href='https://www.back4app.com/docs-containers/custom-domain'
                target='_blank'
                rel='noopener noreferrer'
                className='text-light-blue hover:underline hover:underline-offset-2'
              >
                check the <strong>documentation</strong>.
              </a>
            </div>
            <div className='w-full flex gap-6 mt-6'>
              <TextInput
                value={customDomainInput.value}
                label='Domain name'
                name='domain'
                onChange={(e) => dispatch(setCustomDomainFormInputText(e.target.value))}
                placeholderText='customdomain.com'
                isError={!customDomainInput.isValid}
                errorMessage={customDomainInput.errorMsg}
                isDisabled={isCreatingCustomDomain}
              />
              <SelectInput isDisabled={isCreatingCustomDomain} onChange={(e) => dispatch(setCreateCustomDomainRedirectTo(e.target.value))} optionList={redirectToOptionList} value={redirectTo ? redirectTo.id : ''} label={"Redirect to"} />
            </div>
            <div className="mt-8 flex justify-start items-center gap-2">
              <button className="px-6 py-3 outline-none border border-light-grey rounded-[0.3125rem] font-bold text-sm text-light-grey opacity-70 hover:opacity-80 duration-300" onClick={() => dispatch(setCreateCustomDomainForm(false))}>Cancel</button>
              <button className="px-6 py-3 outline-none border border-cta-green bg-cta-green font-bold text-sm rounded-[0.3125rem]" onClick={isCreatingCustomDomain ? undefined : createCustomDomain}>Add{isCreatingCustomDomain ? 'ing...': ''}</button>
            </div>
          </div>
        ) : (
          <button
            className='outline-none px-4 py-[0.8125rem] rounded-[0.3125rem] text-sm bg-old-dark-blue'
            onClick={() => dispatch(setCreateCustomDomainForm(true))}
          >
            Add Domain
          </button>
        )}
      </div>
    </>
  )
}

const DNSInformationBox = ({ customDomain, forceShow, forceClose }: { customDomain: CustomDomain; forceShow: boolean, forceClose: () => void }) => {
  const [isOpen, setIsOpen] = useState(() => {
    let localData = localStorage.getItem(customDomain.id);
    return localData ? JSON.parse(localData) : true;
  });
  const onClose = () => {
    setIsOpen(false);
    localStorage.setItem(customDomain.id, 'false');
    if (forceShow) forceClose();
  };

  if (!isOpen && !forceShow) {
    return null;
  }

  return <div className='relative mb-6 px-6 rounded-lg bg-dark-grey'>
    <button><CloseIcon className={`absolute right-4 top-4 cursor-pointer text-white`} width="10px" height="10px" onClick={onClose} /></button>
    <div className=''>
      Set the following record on your DNS provider to continue.
      <a
        href='https://www.back4app.com/docs-containers/custom-domain'
        target='_blank'
        rel='noopener noreferrer'
        className='text-light-blue hover:underline hover:underline-offset-2 inline-block ml-1 text-sm font-medium'
      >
        Access detailed DNS configuration guidance here.
      </a>
    </div>
    <div className='flex text-sm py-4 gap-8 flex-wrap'>
      <div className="flex-none">
        <div className="font-bold mb-4">Type</div>
        <div className="">CNAME</div>
      </div>
      <div className="flex-none">
        <div className="font-bold mb-4">Name</div>
        <div className="">{customDomain.name}</div>
      </div>
      <div className="flex-grow">
        <div className="font-bold mb-4">Value</div>
        <div className="flex gap-2 items-center"><span className="truncate max-w-xs">{customDomain.cname}</span>
        <span data-tip="Copied!" data-for={`custom-domain-${customDomain.id}`} data-event='click focus'><CopySVG className="text-cta-green hover:cursor-pointer flex-none" onClick={() => navigator.clipboard.writeText(customDomain.cname)} /></span>
        <ReactTooltip 
          className="tooltip shadow-[0px_0px_30px_rgba(0,0,0,0.4)]" 
          delayHide={2000} 
          place="top" 
          effect="solid" 
          html={true} 
          arrowColor="#303338" 
          eventOff="click" 
          afterShow={() => navigator.clipboard.writeText(customDomain.cname)}
        />
      </div>
      </div>
    </div>
  </div>
}

export default CustomDomainSettings