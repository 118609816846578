import styles from '../assets/styles/NewContainerFlow.module.css';
import newContainerLogo from "../assets/images/containers-logo.svg";
import backIcon from "../assets/images/back-icon.svg";
import {ReactComponent as SearchIcon} from "../assets/images/search-icon.svg";
import { GitHubRepository, Subscription } from '@back4app2/sdk/lib/Back4app2';
import { AuthenticationError, AuthorizationError, NetworkError, GitHubCodeError, GitHubInstallationIdError, DuplicatedError, NotFoundError, BlockedResourceError, UserVerificationPendingError, CreditCardValidationRequiredError } from '@back4app2/sdk';
import back4app2 from '../back4app2';

import { useEffect, useReducer, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import IntegrateGithub from '../components/IntegrateGithub/IntegrateGithub';
import Button from '../components/Button';

import { ReactComponent as GithubIcon } from "../assets/images/github-logo.svg";
import { ReactComponent as BranchIcon } from "../assets/images/branch-icon.svg";
import { ReactComponent as B4AIconSVG } from "../assets/images/b4a-sqaure-glow-logo.svg";
import { ContainerType } from '../types/containerTypes';

import CreateContainerForm from './CreateContainerForm';

import { BACK4APP_DOT_COM_SITE_URL } from '../settings';
import { AmplitudeEvent, trackEvent } from '../utils/amplitude';

interface NewContainerFlowState {
  step: number;
  gitHubRepositories: GitHubRepository[] | undefined,
  
  isLoadingRepository: boolean, // do we really need it?
  loadingRepositoryErrorMsg: string | undefined,
  
  isImporting: boolean,
  importingRepoError: string | undefined;
  isImportingSuccess: boolean;
  showCreditCardRequired: boolean;
  
  selectedRepository: GitHubRepository | undefined,
  isCreatingApp: boolean;
  creatingAppErrorMessage: string;
};

const INITIAL_STATE:  NewContainerFlowState = {
  step: 0,
  
  gitHubRepositories: undefined,
  isLoadingRepository: true,
  loadingRepositoryErrorMsg: undefined,
  
  isImporting: false,
  importingRepoError: undefined,
  isImportingSuccess: false,
  showCreditCardRequired: false,

  selectedRepository: undefined,

  isCreatingApp: false,
  creatingAppErrorMessage: ''
};

enum ContainerFlowActionType {
  Reset,
  StartImportingRepository,
  ImportingRepoSuccess,
  ImportingRepoError,
  SetSelectedRepository,
  SetCurrentStep,
  SetGithubRepositories,
  SetFetchGithubRepositoriesError,
  SetContainerName,
  StartCreatingApp,
  FinishCreatingApp,
  ShowCardRequired,
}

const reset = () => ({ type: ContainerFlowActionType.Reset } as const);
const updateCurrentStep = (value: number) => ({ type: ContainerFlowActionType.SetCurrentStep, payload: value } as const);

const setGithubRepositories = (value: GitHubRepository[] | undefined) => ({ type: ContainerFlowActionType.SetGithubRepositories, payload: value } as const);
const setFetchGithubRepositoriesError = (message: string) => ({ type: ContainerFlowActionType.SetFetchGithubRepositoriesError, payload: message } as const);

const startImportingRepository = () => ({ type: ContainerFlowActionType.StartImportingRepository } as const);
const importRepositorySuccess = () => ({ type: ContainerFlowActionType.ImportingRepoSuccess } as const);
const importRepositoryError = (message: string) => ({ type: ContainerFlowActionType.ImportingRepoError, payload: message } as const);
const setShowCreditCardRequired = (message: string) => ({ type: ContainerFlowActionType.ShowCardRequired, payload: message } as const);

const setSelectedRepository = (repo: GitHubRepository) => ({ type: ContainerFlowActionType.SetSelectedRepository, payload: repo } as const);

const setContainerName = (value: string) => ({
  type: ContainerFlowActionType.SetContainerName,
  payload: {
    value
  }
} as const);

const startCreatingApp = () => ({
  type: ContainerFlowActionType.StartCreatingApp,
} as const);

const finishCreatingApp = (errorMessage?: string) => ({
  type: ContainerFlowActionType.FinishCreatingApp,
  payload: {
    errorMessage
  }
} as const);

type NewContainerFlowAction = 
  | ReturnType<typeof reset> 
  | ReturnType<typeof startImportingRepository> 
  | ReturnType<typeof importRepositorySuccess> 
  | ReturnType<typeof importRepositoryError> 
  | ReturnType<typeof setSelectedRepository> 
  | ReturnType<typeof updateCurrentStep>
  | ReturnType<typeof setGithubRepositories>
  | ReturnType<typeof setFetchGithubRepositoriesError>
  | ReturnType<typeof setContainerName>
  | ReturnType<typeof startCreatingApp>
  | ReturnType<typeof finishCreatingApp>
  | ReturnType<typeof setShowCreditCardRequired>;

function reducer(state: NewContainerFlowState, action: NewContainerFlowAction): NewContainerFlowState {
  switch (action.type) {
    case ContainerFlowActionType.Reset:
      return { ...INITIAL_STATE };
    
    case ContainerFlowActionType.StartImportingRepository:
      return { ...state, isImporting: true, gitHubRepositories: undefined, importingRepoError: undefined};

    case ContainerFlowActionType.ImportingRepoSuccess:
      return { ...state, isImporting: false, isImportingSuccess: true };

    case ContainerFlowActionType.ImportingRepoError:
      return { ...state, isImporting: false, importingRepoError: action.payload, isImportingSuccess: false };

    case ContainerFlowActionType.ShowCardRequired:
      return { ...state, isImporting: false, importingRepoError: action.payload, isImportingSuccess: false, showCreditCardRequired: true };

    case ContainerFlowActionType.SetSelectedRepository:
      return {
        ...state,
        selectedRepository: action.payload,
        step: 1
      };

    case ContainerFlowActionType.SetCurrentStep:
      return { ...state, step: action.payload, isCreatingApp: false, creatingAppErrorMessage: '' };
      
    case ContainerFlowActionType.SetGithubRepositories:
      return { ...state, gitHubRepositories: action.payload, isLoadingRepository: false };

    case ContainerFlowActionType.SetFetchGithubRepositoriesError:
      return { ...state, isLoadingRepository: false, loadingRepositoryErrorMsg: action.payload}
    
    // case ContainerFlowActionType.SetContainerName:
    //   return {
    //     ...state,
    //     container: {
    //       ...state.container,
    //       name: action.payload.value
    //     }
    //   }
    
    case ContainerFlowActionType.StartCreatingApp:
      return {
        ...state,
        isCreatingApp: true,
        creatingAppErrorMessage: ''
      }

    case ContainerFlowActionType.FinishCreatingApp:
      return {
        ...state,
        isCreatingApp: false,
        creatingAppErrorMessage: action.payload.errorMessage || ''
      }

    default:
      return state;
  }
}

const NewContainerFlow = () => {
  
  const [searchParams] = useSearchParams();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { gitHubRepositories, isImporting, importingRepoError, step, loadingRepositoryErrorMsg, isCreatingApp, creatingAppErrorMessage, selectedRepository, isLoadingRepository, isImportingSuccess, showCreditCardRequired } = state;
  const [searchInputText, setSearchInputText] = useState<string>();
  const navigate = useNavigate();
  
  const code = searchParams.get('code');
  const installationId = searchParams.get('installation_id');
  const setupAction = searchParams.get('setup_action');

  useEffect(() => {
    return () => { dispatch(reset()) };
  }, []);

  useEffect(() => {
    let subscription: Subscription;

    if (step === 0) {
      subscription = back4app2.subscribeToGitHubRepositories((error, snapshot) => {
        if (error) {
          if (error instanceof NetworkError) {
            console.error('network error', error);
            dispatch({ 
              type: ContainerFlowActionType.SetFetchGithubRepositoriesError, 
              payload: 'Network error when loading GitHub repositories. Please check your internet connection or try again later.'
            });
          } else if (error instanceof AuthenticationError || error instanceof AuthorizationError) {
            window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
          } else {
            console.error('unexpected error loading GitHub repositories', error);
            dispatch({ 
              type: ContainerFlowActionType.SetFetchGithubRepositoriesError, 
              payload: 'Unexpected error when loading GitHub repositories. Please try again later.'
            });
          }
  
          subscription.unsubscribe();
        } else {
          dispatch({
            type: ContainerFlowActionType.SetGithubRepositories,
            payload: snapshot
          });
        }
      });
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe()
      }      
    };
  }, [step]);

  useEffect(() => {
    
    if (!code || !installationId || !setupAction || !['install', 'update'].includes(setupAction)) {
      if ((window.opener && window.opener !== window) || window.name === 'GithubIntegrationWindow') {
        dispatch({
          type: ContainerFlowActionType.ImportingRepoError,
          payload: 'Something went wrong! Please try again after uninstalling the app.'
        });
        new Promise<void>(res => setTimeout(() => {
          window.close();
          res();
        }, 8_000));
      }
      return;
    }
    
    dispatch({ type: ContainerFlowActionType.StartImportingRepository });

    let searchParamsChanged = false, closeWindow = true, displayError = false;
    
    (async () => {
      try {
        await back4app2.importGitHubInstallation(code, installationId);
      } catch (e) {
        displayError = true;
        if (searchParamsChanged) {
          console.error('error importing GitHub installation', e);
        } else if (e instanceof NetworkError) {
          console.error('network error', e);
          dispatch({
            type: ContainerFlowActionType.ImportingRepoError,
            payload: 'Network error when importing from GitHub. Please check your internet connection or try again later.'
          });
        } else if (e instanceof AuthenticationError || e instanceof AuthorizationError) {
          window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
        } else if (e instanceof GitHubCodeError || e instanceof GitHubInstallationIdError) {
          dispatch({
            type: ContainerFlowActionType.ImportingRepoError,
            payload: 'This GitHub installation is invalid or has expired. Please try again.'
          });
        } else if (e instanceof BlockedResourceError) {
          dispatch({
            type: ContainerFlowActionType.ImportingRepoError,
            payload: e.message
          });
        }  else if (e instanceof CreditCardValidationRequiredError) {
          closeWindow = false;
          dispatch({
            type: ContainerFlowActionType.ShowCardRequired,
            payload: e.message
          });
        } else {
          console.error('unexpected error importing GitHub installation', e);
          dispatch({
            type: ContainerFlowActionType.ImportingRepoError,
            payload: 'Unexpected error when importing from GitHub. Please try again later.'
          });
        }
      }

      if (!searchParamsChanged && !displayError) {
        dispatch({ type: ContainerFlowActionType.ImportingRepoSuccess });
        window.close();
      } else {
        if (closeWindow && !searchParamsChanged) {
          await new Promise(res => setTimeout(res, 8_000));
          window.close();
        }
      }
    })();
    return () => { 
      searchParamsChanged = true;
    };
  }, [code, installationId, setupAction]);

  useEffect(() => {
    step === 0 ? trackEvent(AmplitudeEvent.AT_SELECT_REPOSITORY_PAGE) : trackEvent(AmplitudeEvent.AT_CREATE_APP_PAGE);
  }, [step]);

  const onImportRepository = (repository: GitHubRepository) => {
    dispatch({
      type: ContainerFlowActionType.SetSelectedRepository,
      payload: repository
    });
  }
  
  const onClickBack = () => {
    if (step === 0) {
      navigate(`/apps`);
    } else if (step > 0) {
      dispatch({ type: ContainerFlowActionType.SetCurrentStep, payload: state.step - 1 });
    }
  }

  const onContainerFormSubmit = async (container: ContainerType) => {
    trackEvent(AmplitudeEvent.CREATE_APP_CLICK);
    dispatch(startCreatingApp());

    let appId: string;

    try {
      appId = await back4app2.createAppFromRepository(container.name, container.selectedRepo!.id, container.branch, container.rootDirPath, container.autoDeploy, container.envVars, container.healthCheckEndpoint, container.exposedPort);
    } catch (e) {
      if (e instanceof NetworkError) {
        console.error('network error', e);
        dispatch(finishCreatingApp('Network error when creating app. Check your internet connection.'));
      } else if (e instanceof AuthenticationError || e instanceof AuthorizationError) {
        window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
      } else if (e instanceof DuplicatedError) {
        dispatch(finishCreatingApp('You already have an app with this same name.'));
      } else if (e instanceof NotFoundError) {
        dispatch(finishCreatingApp('The GitHub repository was not found. Please try to select the app repository again.'));
      } else if (e instanceof BlockedResourceError) {
        dispatch(finishCreatingApp(e.message));
      } else if (e instanceof UserVerificationPendingError) {
        dispatch(finishCreatingApp('Please verify your email to continue creating new apps.'));
      } else {
        console.error('unexpected error creating app', e);
        dispatch(finishCreatingApp('Unexpected error when creating app.'));
      }

      return;
    }

    navigate(`/apps/${appId}`);
  };

  const showContent = (step: number) => {
    switch (step) {
      case 0:
        return <IntegrateGithub githubRepos={gitHubRepositories} isLoadingRepository={isLoadingRepository} isImporting={isImporting} errorMsg={importingRepoError || loadingRepositoryErrorMsg} onImport={onImportRepository} filterText={searchInputText} />
      case 1:
        return <CreateContainerForm isCreatingApp={isCreatingApp} creatingAppErrorMessage={creatingAppErrorMessage} onCancel={onClickBack} onSubmit={onContainerFormSubmit} selectedRepository={selectedRepository} />
      default:
        return <div>Ooops!! sorry 😜</div>
    }
  }

  if (isImporting || (isImportingSuccess) || importingRepoError) {
    let content;
    if (isImportingSuccess) {
      content = <div className="flex h-full justify-center items-start flex-col">
        <h1 className="font-sora text-3xl text-center mb-4">Github Installation Completed</h1>
        <div className="font-inter text-sm text-left">This window will close automatically.</div>
      </div>
    } else if (importingRepoError) {
      content = <div className="flex h-full justify-center items-start flex-col">
        <h1 className="font-sora text-3xl mb-4">{importingRepoError}</h1>
        <div className="font-inter text-sm">This window will close automatically in 8 seconds.</div>
      </div>
      if (showCreditCardRequired) {
        content = <div className="flex h-full justify-center items-start flex-col pl-4">
          <h1 className="font-sora text-3xl mb-4">Please validate your credit card to proceed.</h1>
          <div className="w-full">
            <Button type='primary' onClick={() => window.close()}><a role="button" target="_blank" rel='noreferrer noopener' className='font-semibold text-sm' href={`https://checkout.back4app.io/subscription/0EeFjudf6H`}>Validate Now</a></Button>
            <Button type='secondary' onClick={() => window.close()} className='text-sm border-[#fafafa33] font-medium ml-4'>Cancel</Button>
          </div>
        </div>
      }
    } else {
      content = <div className="flex h-full justify-center items-center flex-col">
        <div className="flex justify-center items-center overflow-visible h-6 mb-4 z-[-1]">
          <GithubIcon width="24px" height="24px" />
          <div className="flex space-x-2 ml-5">
            <span className="w-1 h-1 rounded bg-[#D9D9D9] animate-dot-flashing"></span>
            <span className="w-1 h-1 rounded bg-[#D9D9D9] animate-[dot-flashing_1s_infinite_0.5s_alternate] "></span>
            <span className="w-1 h-1 rounded bg-[#D9D9D9] animate-[dot-flashing_1s_infinite_1s_alternate] "></span>
          </div>
          <B4AIconSVG width="76px" height="76px" />
        </div>
        <div className="font-sora font-semibold text-lg leading-140 text-center">Importing repositories...</div>
      </div>
    }
    return <div className="absolute w-full h-full top-0 left-0 bottom-0 right-0 bg-dark-blue z-20 p-4">
      {content}
    </div>
  }

  return <div className='relative overflow-y-auto'>
    <div className={styles.container}>
      <div className={styles.header}  >
        <img src={newContainerLogo} alt="create new container" width="20" height="20" />
        <h1 className={styles.headerText}>{step ===0 ? 'Deploy a Web Application':`You're almost done` }</h1>
      </div>
   

      <p className={styles.headerDesc}>{step ===0 ? 'Easily deploy a new web application from your source code by selecting an existing GitHub repository.':'Name your web application and set up its initial deployment for the first container.' }</p>

      {/* main content */}
      <div className={styles.newContainerWrapper}>
        {/* Header part */}
        <div className={styles.newContainerHeader}>
          {/* left part */}
          <div className={styles.headerLeftContent}>
            <img src={backIcon} alt="go back" width="35" height="35" onClick={onClickBack} className={styles.backIcon}  />
            <div>{step === 0 ? 'Select a GitHub repository' : 'Configure your initial deployment'}</div>
          </div>
          {/* right part */}
          {step === 0 ? (
            gitHubRepositories?.length ? <div className={`${styles.headerRightContentInput} ${!!gitHubRepositories?.length ? '' : styles.disabled}`}>
            <input className={styles.searchInput} placeholder="Search" disabled={!gitHubRepositories?.length} value={searchInputText} onChange={(e) => setSearchInputText(e.target.value)} />  
            {/* <img src={searchIcon} alt="search repos" /> */}
            <SearchIcon className={styles.searchIcon} />
          </div> : null
          ) : (
            <div className={`${styles.headerRightContent}`}>
              <div className={styles.selectedRepoInfo}>
                <GithubIcon width="20px" height="20px" className={styles.left} />
                <span className={styles.selectedRepoText}>{selectedRepository?.fullName}</span>
              </div>
              <div className={styles.selectedRepoInfo}>
                <span className={styles.selectedRepoBranch}>{selectedRepository?.defaultBranch}</span>
                <BranchIcon className={styles.right} />
              </div>
            </div>
          )}
        </div>
        <div className="">
          {showContent(step)}
        </div>
      </div>
    </div>
  </div>;
};

export default NewContainerFlow;
