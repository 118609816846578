const Guides = () => {
  return (<>
    <h1 className="font-sora text-[1.375rem] leading-[140%]">Guides & Docs</h1>
    <div className="mt-4 flex flex-col lg:flex-row gap-2 lg:gap-4">
      <ul className="lg:w-1/2 pl-2 flex flex-col gap-2 list-disc list-inside">
        <li>
          <a href="https://www.back4app.com/docs-containers" rel="noreferrer" target="_blank" className="text-sm leading-[140%]">Get Started</a>
        </li>
        <li>
          <a href="https://www.back4app.com/docs-containers/integrate-with-github" rel="noreferrer" target="_blank" className="text-sm leading-[140%]">Creating a new web application</a>
        </li>
      </ul>
      <ul className="lg:w-1/2 pl-2 flex flex-col gap-2 list-disc list-inside">
      <li>
          <a href="https://www.back4app.com/docs-containers/deployment-process" rel="noreferrer" target="_blank" className="text-sm leading-[140%]">Manage your web application</a>
        </li>
        <li>
          <a href="https://www.back4app.com/docs-containers/troubleshooting" rel="noreferrer" target="_blank" className="text-sm leading-[140%]">Troubleshooting</a>
        </li>
      </ul>
    </div>
  </>);
}

export default Guides;
